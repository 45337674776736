import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/rtl-support.scss";
import "./assets/css/core.scss";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faWhatsapp,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";

import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { RTL_LANGUAGES } from "./i18n";

const Navbar = lazy(() => import("./components/Navbar"));
const Header = lazy(() => import("./components/Header"));
const MobileNav = lazy(() => import("./components/MobileNav"));
const MobileTabs = lazy(() => import("./components/MobileTabs"));
const WhatsappICON = lazy(() => import("./components/WhatsappICON"));
const SearchScreen = lazy(() => import("./components/SearchScreen"));
const Footer = lazy(() => import("./components/Footer"));
const Loading = lazy(() => import("./components/Loading"));

const Home = lazy(() => import("./pages/Home"));
const PageNotFound = lazy(() => import("./pages/PageNotFound"));
const ProductsPage = lazy(() => import("./pages/ProductsPage"));
const PortfolioPage = lazy(() => import("./pages/PortfolioPage"));
const WorkshopPage = lazy(() => import("./pages/WorkshopPage"));
const ContactPage = lazy(() => import("./pages/ContactPage"));
const AboutPage = lazy(() => import("./pages/AboutPage"));
const SearchPage = lazy(() => import("./pages/SearchPage"));

const App = () => {
  const { i18n } = useTranslation();
  document.dir = i18n.dir();

  useEffect(() => {
    if (RTL_LANGUAGES.includes(i18n.language)) {
      const bootstrapRtlLink = document.createElement("link");
      bootstrapRtlLink.rel = "stylesheet";
      bootstrapRtlLink.href =
        "https://cdn.rtlcss.com/bootstrap/v5.0.3/css/bootstrap.min.css";
      document.head.appendChild(bootstrapRtlLink);
      document.documentElement.lang = "ar";
      document.body.dir = "rtl";
      document.querySelectorAll(".fj-gallery").forEach((gallery) => {
        gallery.setAttribute("dir", "ltr");
      });
    } else {
      const bootstrapRtlLink = document.querySelector(
        'link[href="https://cdn.rtlcss.com/bootstrap/v5.0.3/css/bootstrap.min.css"]'
      );
      if (bootstrapRtlLink) {
        bootstrapRtlLink.remove();
      }
      document.body.dir = "ltr";
    }
  }, [i18n.language]);

  const [isActiveSearchBar, setIsActiveSearchBar] = useState(false);

  const media = [
    { icon: <FontAwesomeIcon icon={faInstagram} />, link: "#" },
    { icon: <FontAwesomeIcon icon={faWhatsapp} />, link: "#" },
    { icon: <FontAwesomeIcon icon={faFacebook} />, link: "#" },
  ];

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const [request, setRequest] = useState("");

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Router>
          <Header />
          <SearchScreen
            setIsActiveSearchBar={setIsActiveSearchBar}
            isActiveSearchBar={isActiveSearchBar}
            request={request}
            setRequest={setRequest}
          />
          <Navbar
            media={media}
            setIsActiveSearchBar={setIsActiveSearchBar}
            isActiveSearchBar={isActiveSearchBar}
          />
          <MobileNav />
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route index element={<Home media={media} />} />
              <Route path="/products" element={<ProductsPage />} />
              <Route path="/portfolio" element={<PortfolioPage />} />
              <Route
                path="/portfolio/category/:category"
                element={<PortfolioPage />}
              />
              <Route path="/contact" element={<ContactPage media={media} />} />
              <Route path="/workshop" element={<WorkshopPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route
                path="/portfolio/search/:search"
                element={<SearchPage />}
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Suspense>
          <Footer media={media} />
          <WhatsappICON media={media[1].link} />
          <MobileTabs />
        </Router>
      )}
    </>
  );
};

export default App;
