import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Theme Name: Artistry Canvas
// Author: ACHRAF LAFKIRI
// Author Store: https://www.templatemonster.com/store/TemplateBytes/
// Version: 2.0.0
// Last Update: 07/04/2024